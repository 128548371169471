import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'

export const useMapStore = defineStore('map', () => {
  const gmap = ref(null)
  const returnLoading = ref(false)
  const gpsLoading = ref(false)
  const inputDestinationValue = ref(null)
  const inputOriginValue = ref(null)
  const origin = ref({
    lat: 13.7490527,
    lng: 100.5674282
  })
  const findNearByStationShow = ref(false)
  const listOfFarestLocation = ref([])
  const destination = ref(null)
  const sticked = ref(false)
  const lat = ref(13.7490527)
  const lng = ref(100.5674282)
  const gpsPositionLat = ref(13.7490527)
  const gpsPositionLng = ref(100.5674282)
  const zoom = ref(16)
  const radius = ref(1000)
  const fitingBoundEvent = ref(false)
  const changeformPlaceChange = ref(false)
  const locationUpdateTrigger = ref(0)
  
  const circleId = ref([])
  const stationsMarkerId = ref([])
  const waypointMarkerId = ref([])
  const polylineId = ref([])
  const originMarkerId = ref(null)
  const destinationMarkerId = ref(null)

  const setCenter = async (positionDetail) => {
    lat.value = positionDetail.lat
    lng.value = positionDetail.lng
    zoom.value = positionDetail.zoom || 16
    locationUpdateTrigger.value++
  }

  const setRoute = (postition, point) => {
    if(postition === null || !postition.lat || !postition.lng) {
      if (point === 'origin') {
        origin.value = null
      } else {
        destination.value = null
      }
      return
    } else {
      postition.lat = +postition.lat.toFixed(6)
      postition.lng = +postition.lng.toFixed(6)
    }


    if (point === 'origin') {
      if(origin.value?.lat === postition.lat && origin.value?.lng === postition.lng) return
      origin.value = postition
    } else {
      if(destination.value?.lat === postition.lat && destination.value?.lng === postition.lng) return
      sticked.value = true
      destination.value = postition
    }
  }

  const fireFitingBoundEvent = () => {
    fitingBoundEvent.value = true
    zoom.value = 19
    setTimeout(() => {
      fitingBoundEvent.value = false
    }, 300)
  }

  return { destinationMarkerId, circleId, stationsMarkerId, waypointMarkerId, polylineId, originMarkerId, locationUpdateTrigger, findNearByStationShow, changeformPlaceChange, listOfFarestLocation, radius, sticked, returnLoading, gpsLoading, gpsPositionLat, gpsPositionLng, origin, destination, lat, lng, zoom, fitingBoundEvent, inputDestinationValue, inputOriginValue, gmap, setRoute, setCenter, fireFitingBoundEvent }
})

export function useMapStoreRefs () {
  const mapStore = useMapStore()
  return storeToRefs(mapStore)
}
